import React from 'react';
import LandingPageView2 from 'views/LandingPageView2';
import Main from 'layouts/Main';
import WithLandingPageLayout from 'WithLandingPageLayout';
import { Helmet } from 'react-helmet';

// DATA

const title = 'Get Discounts on Cloud Storage Pricing';
const content = {
  hero: {
    title: "One-Stop Shop for",
    emphasized: "Cloud Storage Pricing.",
    subtitle: "There is a better way to shop for cloud storage.",
    titleVariant: "h2",
    subtitleVariant: "h4",      
  },
  quoteForm: {
    quote_provider:"azure",
    title: "Save 60% or more on cloud storage pricing from major providers",  
    subtitle: "Let managed service providers bid for your business",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "blob-storage",   
  },
  subText: {
    title: "Cloud Storage Shopping",
    emphasized: "Simplified.",
    subtitle: "Your time is important, we can help you reduce Cloud Storage costs by providing you with competitive quotes from Cloud Storage partners which are up to 60% lower than Cloud Storage pricing. Quote, Compare and Save.",
    titleVariant: "h5",
    subtitleVariant: "h6",     
  }
};

// END DATA

const LandingPage = () => {
  return (
  <>
  <Helmet
    defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
    title={title}
    titleTemplate="%s | WindRate.com"
  />     
    <WithLandingPageLayout
      data={content}
      component={LandingPageView2}
      layout={Main}
    />
    </>
  )
};
 
 export default LandingPage;
 